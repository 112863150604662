import axios from "../../Api/axios";

const setAuthToken = (token) => {
  // axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;
