import React, { useState, useEffect } from "react";
import styles from "./posts.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data } = await axios.get("https://drala.io/api/news/get");
        console.log("response", data.data);
        setPosts(data.data);
      } catch (error) {
        console.log(
          "🚀 ~ file: posts.jsx ~ line 14 ~ fetchPosts ~ error",
          error
        );
      }
    };
    fetchPosts();
  }, []);

  return (
    <div className={styles.posts}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: "56px",
          gap: "20px",
        }}
      >
        <h2>NEWS</h2>
        <a className={styles.link} href="/propositions">
          Propositions
        </a>
      </div>
      <div>
     
      </div>
      <ul className={styles.mainPosts}>
        {posts.length !== 0 ? (
          <>
            {[...posts].reverse().map((item, i) => (
              <li key={i}>
                <div className={styles.post}>
                  {/* Title */}
                  <h3 className={styles.postTitle}>{item.title}</h3>
                  {/* Date */}
                  <p className={styles.postDate}>
                    {" "}
                    {new Date(item.createdAt).toLocaleDateString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  {/* Image */}
                  <div className={styles.postImageDiv}>
                    <img
                      className={styles.postImg}
                      src={
                        item.image!='' && item.image !=='noimage'
                          ? item.image
                          : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
                      }
                      alt=""
                    />
                  </div>
                  {/* Description */}
                  <div dangerouslySetInnerHTML={{ __html: item.description && item.description.slice(0, 80)+'...'}} />
                    <br/>
                    <Link to={`/singlepostPage/${item._id}`}>...Read More</Link>
                  {/*<p
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    {item.description && item.description.slice(0, 200)}
                    <Link to={`/singlepostPage/${item._id}`}>...Read More</Link>
                  </p>*/}
                </div>
              </li>
            ))}
          </>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
}

export default Posts;
