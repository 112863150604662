// import { Router } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styles from "./App.module.css";
import FAQ from "./components/context/FAQ";
import Educationalpaper from "./components/context/educationalPaper";
import News from "./components/news";
import UseDrala from "./components/usedrala";
import Chat from "./components/wallet/chat/Chat";
import { Home } from "./containers/Home";
import Create from "./components/Login/Create/Create";
import Login from "./components/Login/log";
import ScrollToTop from "./components/ScrollToTop";
import Assets from "./components/assets";
import Communities from "./components/communities";
import Propositions from "./components/context/propositions";
import Exchange from "./components/exchange";
import Explorer from "./components/explorer";
import ContactUs from "./components/footer/contactUs/index";
import ImportWallet from "./components/importWallet/importWallet";
import SinglepostPage from "./components/news/singlepostPage";
import ACLists from "./components/usedrala/acLists";
import PublicRoutes from "./components/HOC/PublicRouter";
import PrivacyPolicy from "./components/Policys/PrivacyPolicy";
import TermsOfServices from "./components/Policys/TermsOfServices";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { userLoginHelper } from "./store/actions/userAction";
import setAuthToken from "./store/helper/setAuthToken";
import { store } from "./store/store";
import Landing from "./wallet/ladning/Landing";
import { ToastContainer } from "react-toastify";

if (window.localStorage.thisIsMyName) {
  const localStorageObj = JSON.parse(localStorage.thisIsMyName);
  setAuthToken(localStorage.thisIsMyNameToken);
  store.dispatch(userLoginHelper(localStorageObj));
}

const queryClient = new QueryClient();
const App = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <BrowserRouter>
          <div className={styles.App}>
            <>
              <ScrollToTop />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/educationalPaper"
                  component={Educationalpaper}
                />
                <PublicRoutes exact path="/login" component={Login} />
                <Route exact path="/wallet-landing" component={Landing} />

                <Route exact path="/FAQ" component={FAQ} />
                <Route exact path="/news" component={News} />
                <Route exact path="/usedrala" component={UseDrala} />
                <Route exact path="/chat" component={Chat} />
                <Route exact path="/assets" component={Assets} />
                <Route exact path="/explorer" component={Explorer} />
                <Route exact path="/exchange" component={Exchange} />
                <Route exact path="/communities" component={Communities} />
                <Route exact path="/acLists" component={ACLists} />
                <Route exact path="/contactUs" component={ContactUs} />
                <Route exact path="/propositions" component={Propositions} />
                <Route
                  exact
                  path="/singlepostPage/:id"
                  component={SinglepostPage}
                />
                <Route exact path="/importWallet" component={ImportWallet} />
                <Route exact path="/create" component={Create} />
                <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />

                <Route
                  exact
                  path="/TermsOfServices"
                  component={TermsOfServices}
                />
                <Route
                  component={() => {
                    return (
                      <>
                        <Navbar />
                        <section className={styles.not_found_section}>
                          <img
                            src="/image/page_not_found.svg"
                            alt="Page Not Found!"
                          />
                          <h1>Page Not Found!</h1>
                        </section>
                        <Footer />
                      </>
                    );
                  }}
                />
              </Switch>
            </>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
};

export default App;
