import React from "react";
import styles from "./index.module.css";
import Footer from "../../footer";
import Navbar from "../../navbar";
// import Sidebar from "../sidebar/sidebar";
import SinglePost from "./singlePost";

function SinglepostPage(post) {
  return (
    <>
      <Navbar />
      <div id={styles.singleContainer}>
        {/* post */}
        <SinglePost post={post} />
        {/* <Sidebar /> */}
      </div>
      <Footer />
    </>
  );
}

export default SinglepostPage;
