import React from "react";
import styles from "./index.module.css";
import Navbar from "../../navbar";
import Footer from "../../footer";
import src1 from "../../../images/image319.png";
import src2 from "../../../images/educationalpic.jpg";
import src3 from "../../../images/image 320.png";
import { useQuery } from "@tanstack/react-query";
import { getClientContent } from "../../../Api/content.api";

function Educationalpaper() {
  const content = useQuery(
    ["propositionContent"],
    () => getClientContent("12"),
    {
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <>
      <Navbar />
      <div className={styles.contentContainer}>
        <h1>Education Paper</h1>
        <p>
          <strong>(First Produced July 2021)</strong>
        </p>
        {content.isSuccess && (
          <div
            className="document"
            dangerouslySetInnerHTML={{
              __html: content.data.data.content,
            }}
          />
        )}
      </div>
      <div style={{ paddingTop: "10%" }}>
        <Footer />
      </div>
    </>
  );
}

export default Educationalpaper;
