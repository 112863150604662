import React, { useState } from "react";
import Footer from "../../footer";
import Navbar from "../../navbar";
import { Link, useHistory, NavLink } from "react-router-dom";
import CreateCSS from "../Create/create.module.css";
import PinCSS from "../pin/pin.module.css";

import { userSignup } from "../../../store/actions/userAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { EyeCloseIcon, EyeOpenIcon } from "../../../Icons/Icons";


const Create = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [tosAgreed, setTosAgreed] = React.useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [userName, setUserName] = useState("");

  function handleChange(e) {
    setTosAgreed(e.target.checked);
  }

  const [pinType, setPinType] = useState("password");
  const [confirmPinType, setConfirmPinType] = useState("password");

  const updatePinType = () => pinType === "password" ? setPinType("text") : setPinType("password");

  const updateConfirmPinType = () => confirmPinType === "password" ? setConfirmPinType("text") : setConfirmPinType("password");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (tosAgreed) {
      if (password.length === 6) {
        if (password === rePassword) {
          dispatch(userSignup(password, userName, history, toast));
        } else {
          toast.warn(`PIN does not match! `, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        toast.error(
          `Enter a valid 6-digit PIN to protect your wallet and transactions! `,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    } else {
      toast.error(`Please check the Terms of Service & Privacy Policy. `, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className={CreateCSS.body}>
        <div className={CreateCSS.trans}>

          <div className={CreateCSS.head}>
            <div className={CreateCSS.ed}>
              <Link to="/log">
                <i class="fa-solid fa-arrow-left-long"></i>
              </Link>
              <h2>Create a new account</h2>
            </div>
          </div>

          <form className={PinCSS.input}>
            <label for="password">Enter a display name</label>
            <div className={PinCSS.inputGrp}>

              <input
                type="text"
                id="name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <label for="password">Create a 6-digit PIN</label>
            <div className={PinCSS.inputGrp}>

              <input
                type={pinType}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="button" className="transparent-btn" onClick={updatePinType}>
                {
                  pinType === "text" ? <EyeOpenIcon /> : <EyeCloseIcon />
                }
              </button>
            </div>
            <label for="fname">Confirm PIN</label>
            <div className={PinCSS.inputGrp}>
              <input
                type={confirmPinType}
                id="cnfrm-password"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
              />
              <button type="button" className="transparent-btn" onClick={updateConfirmPinType}>
                {
                  confirmPinType === "password" ? <EyeCloseIcon /> : <EyeOpenIcon />
                }
              </button>
            </div>
            <p className={PinCSS.message}>demo text</p>
            <p className="warning-msg m-auto txt-center">*Warning! Only use this online service from your private personal computer that is secure from viruses. If someone has access to your account or steals your Secret Phase, they can steal your Drala!</p>
          </form>
          <div className={CreateCSS.tick}>
            <input type="checkbox" onChange={handleChange}></input>
            <p>
              I agree to all the <NavLink to="/TermsOfServices">Terms of Service</NavLink> and
              <NavLink to="PrivacyPolicy"> Privacy Policy</NavLink>{". "}
            </p>
          </div>

          <button className={CreateCSS.btn} onClick={handleSubmit}>create account</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Create;
