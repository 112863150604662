import React from "react";
import Navbar from "../../navbar";
import Footer from "../../footer";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import "./Chat.css";

const Chat = () => {
  return (
    <div>
      <Navbar />
      <section className="main">
        <Sidebar />
        <div className="mainpage-chat">
          <div className="banners">
            <div className="chat">
              <Link to="/Chat">chat</Link>
            </div>
            <div className="chat">
              <Link to="/Contac">Contacts</Link>
            </div>
          </div>

          <div className="chats">
            <div className="chat1">
              <img
                src="https://www.byrdie.com/thmb/j922lTh-Vn8HnmQsxSKGA0ztxw0=/756x756/filters:no_upscale():max_bytes(150000):strip_icc()/Rob-cc2d68e18be04acf90a74623c1087fd6.jpg"
                alt=""
              />
              <div className="infos">
                <h3 className="name">David</h3>
                <h4>From work</h4>
                <p>wor55.....rthhfjfk</p>
              </div>
            </div>

            <div className="chat1">
              <img
                src="https://www.byrdie.com/thmb/j922lTh-Vn8HnmQsxSKGA0ztxw0=/756x756/filters:no_upscale():max_bytes(150000):strip_icc()/Rob-cc2d68e18be04acf90a74623c1087fd6.jpg"
                alt=""
              />
              <div className="infos">
                <h3 className="name">David</h3>
                <h4>From work</h4>
                <p>wor55.....rthhfjfk</p>
              </div>
            </div>

            <div className="chat1">
              <img
                src="https://www.byrdie.com/thmb/j922lTh-Vn8HnmQsxSKGA0ztxw0=/756x756/filters:no_upscale():max_bytes(150000):strip_icc()/Rob-cc2d68e18be04acf90a74623c1087fd6.jpg"
                alt=""
              />
              <div className="infos">
                <h3 className="name">David</h3>
                <h4>From work</h4>
                <p>wor55.....rthhfjfk</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Chat;
