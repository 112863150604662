import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import src1 from "../../images/image 319.png";
import src2 from "../../images/World 1.jpg";
import src3 from "../../images/DralaPay-Logo 1.png";
import axios from "axios";

function LatestNews() {
  const [getNews, setGetNews] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data } = await axios.get("https://drala.io/api/news/get");

        // const filtered = data.data.filter((item) => item.featured === false);
        setGetNews(data.data);
      } catch (error) {
        console.log(
          "🚀 ~ file: posts.jsx ~ line 14 ~ fetchPosts ~ error",
          error
        );
      }
    };
    fetchPosts();
  }, []);
  return (
    <div id={styles.newContainer}>
      <div className={styles.head}>Latest News</div>
      <div className={styles.boxxContainer}>
        {getNews.length !== 0 ? (
          <>
            {[...getNews]
              .reverse()
              .slice(0, 3)
              .map((item, i) => (
                <div className={styles.box1}>
                  <div className={styles.boxess1}>
                    <img
                      src={
                        item.image !== "false" && item.image !== "noimage"
                          ? item.image
                          : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
                      }
                      alt="Drala"
                    />
                  </div>
                  <div className={styles.boxess2}>
                    <div className="text">
                      <h2> {item.title}</h2>
                      <h4>
                        {" "}
                        {new Date(item.createdAt).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </h4>
                      <div
                        className={styles.cardText}
                        style={{ textAlign: "justify", padding: "0 8px 0 8px" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item.description &&
                              item.description.slice(0, 80) + "...",
                          }}
                        />
                        {/*item.description && item.description.slice(0, 500)*/}
                        <span>
                          {" "}
                          <Link to={`/singlepostPage/${item._id}`}>
                            ...Read More
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            <div className={styles.head}>Opps ! No Latest News Available</div>
          </>
        )}

        {/* <div className={styles.box2}>
          <div className={styles.boxess1}>
            <img src={src2} alt="" />
          </div>
          <div className={styles.boxess2}>
            <div className="text">
              <div>Drala Name</div>
              <div>November 11, 2021</div>
              <p>
                We have been getting people inquiring for more information on
                how our cryptocurrency came to be called Drala. Here is a way
                one non-profit organization describes the name and coin to their
                group.
              </p>
              <p>
                When the Creator created the universe, everything{" "}
                <span>
                  {" "}
                  <Link to="">...Read More</Link>
                </span>
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className={styles.box3}>
          <div className={styles.boxess1}>
            <img src={src3} alt="" />
          </div>
          <div className={styles.boxess2}>
            <div className={styles.text}>
              <div>E-Commerce</div>
              <div>November 20, 2021</div>
              <p>
                <span>
                  {" "}
                  <Link to="">...Read More</Link>
                </span>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LatestNews;
