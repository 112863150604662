import React from 'react'
import styles from "./importWallet.module.css"
import { useHistory } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../footer";
import src from "../../images/backgroundImage/importWallet.png";

import { useDispatch } from "react-redux";
import { userLogin } from "../../store/actions/userAction";
import { toast } from "react-toastify";

export default function ImportWallet() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = React.useState("");

  const handleClick = async (e) => {

    if (inputValue.length === 0) {
      toast.error("Please enter in a correct secret phrase.");
      return;
    }

    try {
      dispatch(userLogin(inputValue, history, toast));
    } catch (error) {
      console.log(
        "🚀 ~ file: importWallet.jsx ~ line 34 ~ handleClick ~ error",
        error
      );
    }
  };

  return (
    <section className={styles.lyt_importWallet_wrapper}>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${src})`,
        }}
        className={styles.importWallet_wrapper_div}
      >
        <div>
          <div className={styles.wrapper_div_backButton}>Import Wallet</div>
          <div className={styles.codeVerificationForm}>
            <div className={styles.codeVerifySection}>
              <label>Enter your 24 words Secret Phrase
                with a space between each word. (The phrase is from left to right and top to
                bottom.)</label>
              <input
                value={inputValue}
                type="text"
                onChange={(e) => setInputValue(e.target.value)}
              />
              <p className="warning-msg m-auto txt-center">*Warning! Only use this online service from your private personal computer that is secure from viruses. If someone has access to your account or steals your Secret Phase, they can steal your Drala!</p>
              <button className={styles.submitButton} onClick={handleClick}>
                Verify Code
              </button>
            </div>
          </div>
          {/* <h2>We are working on that part.We will let you know</h2> */}
        </div>
      </div>
      <Footer />
    </section>
  );
}

const ArrowIcon = (props) => {
  return (
    <svg
      width="55"
      height="46"
      viewBox="0 0 50 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.87868 20.8787C-0.292893 22.0503 -0.292893 23.9497 0.87868 25.1213L19.9706 44.2132C21.1421 45.3848 23.0416 45.3848 24.2132 44.2132C25.3848 43.0416 25.3848 41.1421 24.2132 39.9706L7.24264 23L24.2132 6.02944C25.3848 4.85786 25.3848 2.95837 24.2132 1.7868C23.0416 0.615224 21.1421 0.615224 19.9706 1.7868L0.87868 20.8787ZM3 26H55V20H3V26Z"
        fill="black"
      />
    </svg>
  );
};