import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "./landing.css";
import { AiOutlineLink } from "react-icons/ai";

import { AiFillWindows, AiFillApple, AiFillAndroid } from "react-icons/ai";

export default function Landing() {
  return (
    <>
      <Navbar walletColor="#FED900" />
      <main className="wallet_landing">
        <h2>
          Download your Drala Wallet and get started. Visit the Google Play
          Store to download your Drala Wallet on your Android Phone. Visit the
          App Store to download your Drala Wallet on your iPhone.
        </h2>

        <a className="wallet_landing_link" href="https://explorer.drala.io">
          <AiOutlineLink />
          Drala Wallet Explorer
        </a>

        <p>Available For</p>
        <ul className="wallet_landing_ctas">
          {/* <li className="wallet_landing_items">
            <button className="wallet_landing_button">
              <AiFillWindows size={38} />
              <p className="download_for">Download for</p>
              <p className="download_platform">Windows</p>
              <p className="download__coming_soon">Coming Soon</p>
            </button>
          </li> */}
          <li className="wallet_landing_items">
            <a
              href="https://apps.apple.com/in/app/drala/id6444584858"
              target="_blank"
              rel="noreferrer"
              className="wallet_landing_button"
            >
              <AiFillApple size={38} />
              <p className="download_for">Download for</p>
              <p className="download_platform">IPhone</p>
            </a>
          </li>
          <li className="wallet_landing_items">
            <a
              href="https://play.google.com/store/apps/details?id=com.drala.io"
              target="_blank"
              rel="noreferrer"
              className="wallet_landing_button"
            >
              <AiFillAndroid size={38} />
              <p className="download_for">Download for</p>
              <p className="download_platform">Android</p>
            </a>
          </li>
        </ul>
        <a
          href="/after_wallet_setup.pdf"
          target="_blank"
          className="wallet__pdf__download__btn"
        >
          After Wallet Setup
        </a>
      </main>
      <Footer />
    </>
  );
}
