/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import google_download_png from "../../images/google_download.png";
import apple_download_png from "../../images/apple_download.png";

function Footer() {
  return (
    <>
      <div id={styles.footer}>
        <div className={styles.contactUsSec}>
          <Link to="/contactUs">Contact Us</Link>
        </div>

        <div className={styles.box2}>
          <div className={styles.terms}>
            <h3>
              <Link
                to={"/PrivacyPolicy"}
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy policy
              </Link>
            </h3>
            <h3>
              <Link
                to={"/TermsOfServices"}
                style={{ textDecoration: "none", color: "white" }}
              >
                terms of services
              </Link>
            </h3>
          </div>
          <div className={styles.boxes3}>
            <a
              href="https://www.linkedin.com/in/drala-foundation-b763aa237/"
              target="_blank"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCjYBZpncCLQ70WO6x14LqoA"
              target="_blank"
            >
              <i class="fab fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/dralafoundation/">
              <i class="fab fa-instagram"></i>
            </a>

            <a href="https://twitter.com/DralaFoundation">
              <i class="fab fa-twitter"></i>
            </a>

            <a
              href="https://www.facebook.com/Drala-107261914970733"
              target="_blank"
            >
              <i class="fab fa-facebook"></i>
            </a>
          </div>
          <div className={styles.copy}>
            <h3>Copyright 2021 - {new Date().getFullYear()}</h3>
            <h3>all rights reserved</h3>
          </div>
        </div>

        <div className={styles.download_section}>
          <div className={styles.download_section_links}>
            <a
              href="https://play.google.com/store/apps/details?id=com.drala.io"
              target="_blank"
              rel="noreferrer"
            >
              <img src={google_download_png} alt="Get it on play store" />
            </a>
            <a
              href="https://apps.apple.com/in/app/drala/id6444584858"
              target="_blank"
              rel="noreferrer"
            >
              <img src={apple_download_png} alt="Download on the AppStore" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
