import axios from "./axios";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:3000/api",
  baseURL: "https://drala-cms.vercel.app/api",
});

export const getClientContent = (contentName) => {
  return axiosInstance.get("/content/1/" + contentName);
  // return axiosInstance.get("/cors");
};
