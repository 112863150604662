import React from "react";
import styles from "./index.module.css";
import Footer from "../footer";
import Navbar from "../navbar";
import { Link } from "react-router-dom";
import src1 from "../../images/Group 379.svg";
import src2 from "../../images/Group 406.svg";

function UseDrala() {
  return (
    <>
      <Navbar useDralaColor="#FED900" />
      <div id={styles.usedralaPage}>
        <div id={styles.sectionContainer}>
          <div className={styles.dralaPayBox}>
            <h3>
              Spend your Drala through the partnered online shopping platform.
            </h3>
            <div className={styles.whiteBox}>
              <div className={styles.boxOne}>
                {/* Image */}
                <div className={styles.pay}>
                  <img src={src1} alt="" />
                </div>
                {/* Text */}
                <div className={styles.text}>
                  <p>
                    Safely connect with other community members to buy and sell
                    goods and services using your Drala.
                  </p>
                </div>
                {/* Button */}
                <div>
                  <button
                    onClick={() => {
                      window.location.href = "http://dralapay.com/";
                    }}
                    className={styles.btn}
                  >
                    Shop Now
                  </button>
                </div>
              </div>
              <div className={styles.boxTwo}>
                <img src={src2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.listsBox}>
          <div className={styles.head}>
            <h2>Our top businesses that accept Drala as payment. </h2>
          </div>
          <div className={styles.clickHere}>
            <Link to="/acLists">Click Here</Link>
          </div>
          <div className={styles.paraa}>
            <p>
              If your business wishes to be added to our database, please{" "}
              <Link to="/contactUs">Contact Us.</Link>
            </p>
          </div>
        </div>
        {/* <div className={styles.location}>
          <h3>store location - find store where you can spend your drala</h3>
        </div> */}

        {/* <div className={styles.map}>
          <div className={styles.discription}>
            <div className={styles.search}>
              <input type="text" />
              <button>Search</button>
            </div>

            <div className={styles.boxes}>
              <div className={styles.boxe}>
                <h4>vitamin shoppe chember</h4>
                <p>108 chember str charot blance 17009</p>
                <p className={styles.pa}>1800-7899-09</p>
                <p>BDS Henda</p>
                <p>0.19 mi</p>
                <div className={styles.butt}>
                  <button>view on map</button>
                  <button>direction</button>
                </div>
              </div>

              <div className={styles.boxe}>
                <h4>vitamin shoppe chember</h4>
                <p>108 chember str charot blance 17009</p>
                <p className={styles.pa}>1800-7899-09</p>
                <p>BDS Henda</p>
                <p>0.19 mi</p>
                <div className={styles.butt}>
                  <button>view on map</button>
                  <button>direction</button>
                </div>
              </div>

              <div className={styles.boxe}>
                <h4>vitamin shoppe chember</h4>
                <p>108 chember str charot blance 17009</p>
                <p className={styles.pa}>1800-7899-09</p>
                <p>BDS Henda</p>
                <p>0.18 mi</p>
                <div className={styles.butt}>
                  <button>view on map</button>
                  <button>direction</button>
                </div>
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1QsRNHuQkXzZxGmePl4aljSVTA7BT3XFY&ehbc=2E312F"
            width="640"
            height="480"
          ></iframe>
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default UseDrala;
