import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.location.hash) {
      window.scrollTo(document.getElementById(window.location.hash));
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
