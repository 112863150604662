import axios from "../../Api/axios";
import cryptoJs from "crypto-js";
import setAuthToken from "../helper/setAuthToken";
export const userLoginHelper = (data) => {
  // console.log('userLoginHelper--->', data);
  return {
    type: "SET_USER_AUTH",
    payload: data,
  };
};

const userLogoutHelper = (data) => {
  // console.log('userLogoutHelper--->', data);
  return {
    type: "DELETE_USERS_DATA",
    payload: data,
  };
};

export const userLogin = (inputValue, history, toast) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/user/recoverAcc", {
        mnemonic: inputValue,
      });
      const obj = {
        userAddress: data.data.address,
        secretKey: data.data.mnemonic,
      };
      localStorage.setItem("thisIsMyName", JSON.stringify(obj));
      localStorage.setItem("thisIsMyNameToken", data.token);
      setAuthToken(data.token);
      dispatch(userLoginHelper(obj));

      history.push("/my/wallet");
      toast.success(`🎉 Successful Login!`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      console.log("🚀 ~ file: userAction.js ~ line 38 ~ return ~ err", err);
    }
  };
};

export const userSignup = (password, userName, history, toast) => {
  return async (dispatch) => {
    try {
      const pass = cryptoJs.AES.encrypt(password, "dralaIO@123#@!").toString();
      const { data } = await axios.get("/user/createAcount");
      console.log("🚀 ~ file: userAction.js ~ line 40 ~ return ~ data", data);

      const obj = {
        userPass: pass,
        userName: userName,
        userAddress: data.data.address,
        secretKey: data.data.mnemonic,
      };
      localStorage.setItem("thisIsMyName", JSON.stringify(obj));
      localStorage.setItem("thisIsMyNameToken", data.token);
      setAuthToken(data.token);

      dispatch(userLoginHelper(obj));

      history.push("/my/wallet");
      toast.success(`🎉 You have successfully created your account! `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      console.log("🚀 ~ file: userAction.js ~ line 38 ~ return ~ err", err);
    }
  };
};

export const userLogout = (history, toast) => {
  return (dispatch) => {
    dispatch(userLogoutHelper({}));
    localStorage.removeItem("thisIsMyName");
    history.push("/");
    toast.success(`Successful Logout! `, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
};

// err.response.data.error.email = "ndnodnnlkkdmml"
