//Main entry point for Home page

import React from "react";
import Navbar from "../../components/navbar";
import Section from "../../components/section";
import Context from "../../components/context";
import Footer from "../../components/footer";
import LatestNews from "../../components/latestNews";
import "./home.css";

export function Home() {
  return (
    <>
      <Navbar homeColor="#FED900" />
      <Section />
      <Context />
      <LatestNews />
      <div className="home_support_request">
        <h2>Your Support Is Requested</h2>
        <p>
          The administrators of this website and the Drala Blockchain do this as
          a free-will donation to the Drala Community. No payment of any kind is
          received for their services. Their donations have allowed the Drala to
          become the first decentralized, asset-backed, and transaction-free
          cryptocurrency in the world.
          <br /> <br />
          The administrators have requested that the Drala Community
          occasionally donate to a non-profit supporter. Your donations will
          assist to further educate and distribute the Drala to the world.
          Please consider sending a small donation of Drala to their wallet. If
          you require a written donation receipt, contact us with your name,
          address, telephone number, the amount, and date it was sent. The
          non-profit will send you an official donation receipt for your tax
          records.{" "}
        </p>

        <img src="/daral-support-required.png" alt="QR Code" />
      </div>

      <Footer />
    </>
  );
}
