import axios from "axios";
import React, { useEffect, useState } from "react";
import src13 from "../../images/image 316.png";
import Footer from "../footer";
import Navbar from "../navbar";
import styles from "./communities.module.css";
import "./communitiesStyle.css";

function Communities() {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const { data } = await axios.get(
          "https://drala.io/api/admin/primaryCommunity"
        );

        setData(data.community[0]);
        console.log(data.communities);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCommunity();
  }, []);

  // const content = useQuery(
  //   ["propositionContent"],
  //   () => getClientContent("10"),
  //   {
  //     onSuccess: (data) => {
  //       console.log("data", data);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   }
  // );

  return (
    <>
      <Navbar communitiesColor="#FED900" />
      <div className="image">
        <img src={src13} width={200} alt="" />
      </div>
      <div className={styles.communitiesContainer}>
        {/*content.isSuccess && (
          <div
            className="document"
            dangerouslySetInnerHTML={{
              __html: content.data.data.content,
            }}
          />
          )*/}
        {data && (
          <div
            className="document"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          />
        )}

        <ul className="communities__list">
          {data?.community.map((data) => (
            <li className="communities__list__item">
              <a
                className="communities__list__item__title"
                href={data.website_link ? "https://" + data.website_link : ""}
                target={data.website_link ? "_blank" : "_self"}
                rel="noreferrer"
              >
                {data.name}
              </a>

              <p
                className="communities__list__item__description"
                style={{ fontSize: 12 }}
              >
                {data.details}
              </p>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Communities;
