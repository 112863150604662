import { combineReducers } from "redux";

// reducer import
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";

import { reducer as formReducer } from "redux-form";
// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
  userRoot: userReducer,
  errorRoot: errorReducer,
  form: formReducer,
});

export default reducer;
