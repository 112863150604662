import React from "react";
import styles from "./index.module.css";
import Navbar from "../../navbar";
import Footer from "../../footer";
import { useQuery } from "@tanstack/react-query";
import { getClientContent } from "../../../Api/content.api";

function FAQ() {
  const content = useQuery(
    ["propositionContent"],
    () => getClientContent("11"),
    {
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <>
      <Navbar />
      <div id={styles.containerFaq}>
        <div className={styles.document}>
          {!content.isLoading && (
            <div
              className={styles.document}
              dangerouslySetInnerHTML={{
                __html: content.data.data.content,
              }}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FAQ;
