import React from "react";
import "./style.css";
import Navbar from "../navbar";
import Footer from "../footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div id="containerPolicy">
        <h1>Privacy Policy</h1>
        <div className="textContainerPolicy">
          <h4>THE DRALA DIFFERENCE</h4>
          <br />

          <p style={{ textAlign: "justify" }}>
            We are fully committed to your privacy. The Drala Wallet and
            Drala.io Website are free services and private by design. The Drala
            Wallet puts you in control of your data and Drala cryptocurrency.
            The Drala Wallet enables you to communicate directly with your peers
            in the Community using end-to-end encryption. We cannot see or
            decrypt your communications in the Drala Wallet app. Only the
            contacts you choose to communicate with can decrypt your messages.
          </p>

          <h4>
            WHAT INFORMATION DO I HAVE TO PROVIDE TO USE THE DRALA WALLET?
          </h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            You simply establish an account by registering a “Display Name”
            (which can be a legal name, pseudonym, alias, nickname, and so
            forth). You can also choose to provide an avatar or photo. Your
            Display Name and Picture will only be shared with your Drala
            Contacts.
            <br />
            <br />
            When using your Drala on exchanges or other third-party businesses,
            you may be required to provide additional personal information so
            that they can verify your identity and meet the requirements of
            applicable anti-money laundering and similar legislation (together,
            KYC information). Any third party is bound by confidentiality
            obligations and other restrictions about how they deal with your
            personal information and Drala.io or the Drala Community is not
            responsible for that third party’s compliance with its privacy
            policy or privacy laws.
          </p>

          <h4>WHAT OTHER INFORMATION MIGHT WE COLLECT ABOUT YOU?</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            If you use the Drala Wallet, Drala Website, or other Community
            Services, there may also be the change we collect the following
            information about you when you visit our App (generic data):
            <br />
            <br />
            <li>
              • The IP address of your device when connected to the internet.
            </li>
            <li>• The operating system your device uses.</li>
            <li>
              • Information about your country and the language setting on your
              device.
            </li>
            <li>
              • Other attributes about mobile device and operating system.
            </li>
          </p>

          <h4>PURPOSES FOR WHICH WE COLLECT YOUR PERSONAL INFORMATION</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            The primary purpose for which we or affiliated third parties would
            collect personal information is to provide Services to you and to
            comply with our legal and regulatory obligations in connection with
            the provision of certain Services. In addition, the need to collect
            personal information about you, such as generic data, for the
            following additional purposes:
            <br />
            <br />
            <li>• To validate, update and/or enhance our Services.</li>
            <li>
              • To undertake research, analytics and/or benchmarking, including
              to measure the number of users of any of our Services.
            </li>
            <li>• To identify and understand our users’ needs.</li>
            <li>
              • For internal record keeping, audit, and compliance purposes.
            </li>
            Together, these are the ‘Purposes’.
          </p>

          <h4>HOW WE USE YOUR PERSONAL INFORMATION</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            We will never sell your personal information. We will only disclose
            your personal information when we consider it to be necessary in
            view of the Purpose(s) for which it was collected or where it is
            required by applicable law or directive.
          </p>
          <h4>THIRD PARTIES</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            To enhance the Services available to you, we may introduce other
            third-party applications into the Drala Wallet, Drala Website,
            marketplace, or any other of our services. These third-party
            applications may also collect personal information from or about you
            with your consent. Please note that, in such circumstances, the
            relevant third-party application’s privacy policy will apply to any
            personal information it collects from or about you. You acknowledge
            that the Drala Community will not be responsible for that third
            party’s compliance with its privacy policy or privacy laws.
            <br />
            <br />
            Also, any third-party service provider that we engage will be bound
            by confidentiality obligations and other restrictions with respect
            to their use and collection of collected information. This Policy
            does not apply to, and we are not responsible for, third-party
            cookies, web beacons, or other tracking technologies, which are
            covered by such third-parties’ privacy policies. You acknowledge
            that Drala will not be responsible for that third party’s compliance
            with its privacy policy or privacy laws.
          </p>
          <h4>HOW WE USE YOUR PERSONAL INFORMATION</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            We will never sell your personal information. We will only disclose
            your personal information when we consider it to be necessary in
            view of the Purpose(s) for which it was collected or where it is
            required by applicable law or directive.
          </p>
          <h4>PROTECTING YOUR PERSONAL INFORMATION: STORAGE AND SECURITY</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            We will take all reasonable steps to keep your personal information
            safe from loss, unauthorized activity, or other misuses. In the
            event of a security incident, we have in place procedures to
            promptly investigate the incident and determine if there has been a
            data breach involving any personal information, and if so, to assess
            if it is a breach that would require notification. If it is, we will
            notify affected parties in accordance with applicable privacy law
            requirements.
            <br />
            <br />
            Any personal information about you that Drala holds or controls will
            be erased as soon as it is no longer necessary to achieve the
            Purpose(s) for which it was collected unless we are required by the
            applicable law to store it for a longer period of time, in which
            case it will be erased after the relevant time period has elapsed.
          </p>
          <h4>HOW YOU CAN HELP KEEP YOUR INFORMATION SAFE</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            Please take care when deciding what personal information you send to
            us via email or other communication methods. No internet, email
            transmission, or telephone call is ever fully secure or error-free.
            In particular, emails sent to or from the services may not be secure
            so if you provide us with personal information over the internet,
            the provision of that information is at your own risk.
            <br />
            <br />
            When accessing the Drala, ensure you are using the official website
            at Drala.io and look for the 'padlock' symbol in your web browser to
            ensure the website is secure. Secure Socket Layer (SSL) is the most
            accepted way of ensuring the security of transmitted information to
            and from internet sites worldwide. It operates on all pages of our
            website. We use SSL with Comodo Authentication Certificates with RC4
            128-bit encryption.
          </p>
          <h4>YOUR RIGHT TO ACCESS YOUR PERSONAL INFORMATION</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            You may request access to any of the personal information we hold
            about you at any time. To request access to the personal information
            that we hold about you, please contact us using the contact page at
            Drala.io. There will be a fee for our reasonable costs in retrieving
            and supplying the information to you.
            <br />
            <br />
            We will respond to your request within a reasonable period of time
            and, where reasonable and practicable, grant access to the
            information in the manner requested. An explanation will be provided
            to you if we deny you access to the personal information we hold.
            <br />
            <br />
            If any information we hold about you is incorrect, please contact us
            using the contact page at Drala.io.
          </p>
          <h4>HOW TO CONTACT US</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            If you have any queries about this Policy, please feel free to
            contact us using the contact page at Drala.io.
          </p>

          <h4>PRIVACY LAWS STILL APPLY</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            This Policy does not limit our rights and obligations under
            applicable privacy laws.
          </p>
          <h4>CHANGES TO THIS POLICY</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            We can amend this Policy from time to time in accordance with the
            Drala App Terms or according to government regulations. If you
            continue to access the Drala Services then you will be bound by the
            amended Policy.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
