import React from "react";
import "./style.css";
import Navbar from "../navbar";
import Footer from "../footer";

const TermsOfServices = () => {
  return (
    <div>
      <Navbar />
      <div className="docs-section">
        <h1>Terms of Service</h1>
        <div>
          <h4>Introduction</h4>
          <p>
            Please read these Terms carefully. By using any Services (Wallets,
            Websites, Chat, Merchant, third-party, and so forth) provided by the
            Drala Community you agree to be legally bound by these Terms as they
            are updated from time to time. If this is not acceptable to you,
            please do not use any Services.
          </p>

          <h4>Can you use the Services?</h4>
          <br />
          <p>
            You are only permitted to use the Services if you meet all of the
            following criteria:
            <br />
            <br />
            <ul>
              <li>
                You reside in a country where you are legally permitted to
                access the Services in compliance with these Terms.
              </li>
              <li>
                You are at least the minimum age required to provide informed
                consent according to the laws of your country.
              </li>
              <li>
                You must have the capacity and, in the case of an entity, be
                authorized to enter into and to be bound by the Terms.
              </li>
              <li>
                If you are using Drala on behalf of a business, you have the
                authority to bind the business entity to these Terms.
              </li>
            </ul>
          </p>

          <h4>Drala Account</h4>
          <br />
          <p>
            To use the Services, you need to set up an account. Different
            Community Services or third-party services may require more
            information from you. At the minimum, an account with the Drala
            Wallet will require you to provide a name (which can be your legal
            name, an abbreviated name, a nickname, or an alias) and optionally
            to generate an avatar. Our wallet system will also generate a secret
            phrase that is unique to your account. Your unique secret phrase is
            your ultimate password to access your account if you get locked out.
            You must keep your login credentials and secret phrase confidential!
            You alone are responsible for your login credentials and any
            activity resulting from the use of your login credentials or
            activity on your account.
            <br />
            <br />
            Important: We do not keep any record of your secret phrase.
            Therefore, if you forget or lose it then you will lose access to
            your account permanently. We strongly recommend you use cold storage
            for your secret phrase (that is, write it down on paper and hide it
            in a secret, secure location). You should not share the details of
            your secret phrase with anyone else. If another person learns your
            secret phrase, they can access your account and gain full control of
            any assets or messaging in your Drala wallet.
          </p>
          <br />
          <h4>You use the Services at your own risk.</h4>
          <p>
            You use the Services at your own risk. In particular, you confirm
            you understand and accept the following risks by using the Services:
            <ul>
              <li>
                Drala has no knowledge or visibility of any content shared or
                communicated using its Services. We cannot monitor these
                communications because they are encrypted end-to-end and stored on
                distributed ledgers. You may be exposed to content that you may
                find offensive or objectionable. You accept that we have no
                responsibility for any content shared on the Drala platform nor
                for any emotional, financial, or other harm you may suffer as a
                result of receiving or acting on such content.
              </li>
              <li>
                You understand that you are solely responsible for any content
                you publish, host, share or transfer using the Services and for
                the consequences of sharing it.
              </li>
              <li>
                We do not stand behind or underwrite any transactions involving
                digital assets or currency you enter into using the Services.
              </li>
              <li>
                If you forget or lose access to your secret phrase (see above),
                then you will lose access to your Drala account and wallets and we
                cannot recover them for you. Extreme caution must be taken in
                storing and transmitting your secret phrase. By using any
                Services, you agree that Drala will not be liable for any loss or
                damage arising from unauthorized use of your credentials or from
                your secret phrase being lost or its confidentiality compromised.
              </li>
              <li>
                We have designed a communications network that is
                technologically structured to protect the privacy of your
                communications and personal data. However, we cannot guarantee
                that unauthorized third parties will never be able to defeat those
                measures or access your personal data for improper purposes.
              </li>
              <li>
                Drala, Drala Network, Drala Wallet, Drala Community, Drala
                Website, Drala Associated Partners, and so forth do not conduct
                any independent diligence on or substantive review of any
                blockchain asset, digital currency, cryptocurrency, or associated
                assets. You are solely responsible for evaluating the asset(s) you
                choose to engage with, for determining on your own whether you
                will exchange blockchain assets, and for all your decisions as to
                whether to exchange blockchain assets within the Drala Wallet,
                Drala Network, and so forth.
              </li>
            </ul>
          </p>

          <h4>We can change our Services.</h4>
          <br />
          <p>
            We are constantly changing and improving our Services. We may add or
            remove features or functions and we may suspend or stop providing a
            Service altogether, provided that we will; give you prior notice
            where possible if this could materially and adversely affect you.
          </p>
          <h4>Free & Premium Services</h4>
          <br />
          <p>
            The Services are provided on a “freemium” model. This means that you
            can use basic Services for free. We may also introduce over time
            additional premium services that you can choose to pay for. To
            access the premium Services, you will need to pay the applicable
            fees set in the fee schedule on our website at the relevant time.
            You authorize us or our third-party payment processors to bill you
            and deduct such amounts in advance on a one-time or periodic basis
            as required in accordance with such payment terms.
            <br />
            <br />
            All amounts paid are non-refundable and we reserve the right to
            change our prices in the future. Your continued use of the Services
            after any price change goes into effect constitutes your agreement
            to pay the changed amount. If you dispute any payments, you must let
            us know within 60 days after the invoice date by contacting us
            through the Drala.io website.
          </p>
          <h4>Prohibited Activities</h4>
          <br />
          <p>
            You must not use the Drala Services for any of the following
            purposes (each a ‘Prohibited Activity’):
            <ul>

              <li>
                To conduct or engage in any illegal activity, including (without
                limitation) money laundering, terrorism, or terrorism financing or
                to hide or disguise the proceeds of any such activity.
              </li>
              <li>
                To infringe our proprietary intellectual property or the
                proprietary intellectual property of any other person.
              </li>
              <li>To breach any privacy or data protection laws.</li>
              <li>
                To breach any laws prohibiting harmful communications, or to
                send malicious content (i.e. hate speech or abuse) to any person
                or engage in any deceptive, fraudulent or malicious activity,
                including by launching malware or viruses, or seeking to hack into
                any wallets or steal any digital assets or funds held by any other
                user.
              </li>
              <li>
                To send unsolicited marketing messages or broadcasts (i.e. spam)
                to any person.
              </li>
              <li>To engage in gambling.</li>
              <li>
                In relation to any digital asset that the Services do not
                support at the relevant time.
              </li>
              <li>
                To reverse-engineer, decompile, disable, or disassemble any
                Services.
              </li>
              <li>
                To harm any person, including Drala and our related persons.
              </li>
              <li>
                If you do not meet the eligibility criteria set out in these
                Terms.
              </li>
              <li>
                To offer, or to purport to offer, any Service to any person who
                is not an eligible user.
              </li>
              <li>
                To engage in any other use or activity which breaches these
                Terms.
              </li>
            </ul>
          </p>
          <br />
          <h4>Our Rights and Remedies</h4>
          <p style={{ textAlign: "justify" }}>
            If you engage in any Prohibited Activity, or if we believe in good
            faith that you are engaging in or have engaged in a Prohibited
            Activity, or that your actions or inactions create risks for our
            business or any person connected with our business, we may do some
            or all of the following:
            <br />
            <li>• Issue a warning to you.</li>
            <li>• Limit, suspend, or cancel your access to any Services.</li>
            <li>
              • Warn other users of your actions, including by adding your
              details to a ‘blacklist’ of users which we may publish to other
              users and/or the authorities.
            </li>
            <li>
              • Advise any relevant governmental authorities or regulators and
              co-operate fully with any law enforcement authorities which may
              include disclosing any relevant information.
            </li>
            We are also permitted to suspend or restrict the Services if we
            consider it necessary or desirable to do so as a result of any event
            of force majeure, underlying network change, or where your account
            has been inactive for a prolonged period.
          </p>
          <br />
          <h4>You Indemnify Us</h4>
          <p style={{ textAlign: "justify" }}>
            You indemnify us and hold us harmless for any loss, cost, liability,
            or expense that you sustain or incur as a result of you breaching
            these Terms.
          </p>
          <br />
          <h4>Terminating Your Relationship With Us</h4>
          <p style={{ textAlign: "justify" }}>
            You can end your legal agreement with us by discontinuing your use
            of our Services. To deactivate your account and all data associated,
            you must delete or destroy your secret phrase. Without your secret
            phrase, no one can access your account.
          </p>
          <br />
          <h4>Our Liability is Limited</h4>
          <p style={{ textAlign: "justify" }}>
            To the fullest extent permitted by applicable law, Drala and our
            related companies, partners, third-party affiliates, community
            members, directors, officers, and advisers (together, ‘Drala
            Community or Drala Persons’) exclude all liability and
            responsibility to you and to all third parties for any loss, damage,
            costs or expense, whether in tort (including negligence), contract,
            under statute or otherwise and whether direct, indirect, or
            consequential (including in connection with business interruption,
            loss of data, or damage to hardware or software), which you or any
            other person may suffer or incur in connection with the Services, or
            the use or inability to use the Services, and in respect of any fiat
            currency or digital assets transacted using any Drala Services, or
            otherwise. This exclusion applies whether or not we have been
            informed of the possibility of such loss, damage, cost, or expense
            occurring.
            <br />
            <br />
            Without limiting the foregoing, no Drala Person has any liability
            for any damage, loss (including loss of profit), delay or
            inconvenience, or failure in performance or interruption of any
            Service caused by or resulting from (directly or indirectly):
            <ul>

              <li>
                Any computer virus, spyware, scareware, Trojan horse, worms or
                other malware or cyber, phishing or spoofing attack that may
                affect your computer or other devices, or the data held by any
                person, including any node, relating to the Services.
              </li>
              <li>
                Any cause or condition beyond our reasonable control, including
                but not limited to, any delay or failure due to any act of God,
                act of civil or military authority, act of terrorism, civil
                disturbances, war, strike or other labor dispute, fire,
                interruption in telecommunications or internet services or network
                provider services, failure of equipment or software, cyber-attack,
                or other occurrence or catastrophe.
              </li>
              <li>
                Any emotional distress caused by any content received as a
                result of the Services.
              </li>
            </ul>
            Our warranties are limited to those implied by law. The Drala
            Services are provided without warranties of any kind, either express
            or implied, including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, title, and
            non-infringement except to the extent implied by mandatory law.
            While Drala attempts to provide a good standard of user experience,
            we cannot and do not represent or warrant that the Services will
            always be secure or error-free, or that the Services will always
            function without delays, disruptions, or imperfections. Therefore,
            to the extent permitted by mandatory law we do not warrant the
            reliability, availability, accuracy, completeness or timeliness of
            the information on any website or application, or otherwise in
            connection with the Services. All information is provided “as is”
            without warranty of any kind, express or implied, except to the
            extent implied by mandatory law. Important: We are required to
            provide certain guarantees that cannot be excluded under consumer
            law, if you are using the Services for personal use, including
            guarantees as to the acceptable quality and fitness of purpose of
            the Services. Nothing in these Terms will be read or applied so as
            to exclude, restrict or modify or have the effect of excluding,
            restricting or modifying any condition, warranty, guarantee, right
            or remedy implied by the law and which by law cannot be excluded,
            restricted or modified, even if any other provision of these Terms
            would otherwise suggest that this might be the case. Where and to
            the extent liability for breach or any implied warranty or
            condition, or otherwise, cannot be excluded by applicable law, our
            liability to you is limited (at your option) to: the total amount of
            the fees (if any) we have earned from you as a result of supplying
            the Services to you; or the supply of the Services to you again.
          </p>

          <br />
          <h4>Disputes With Other Users</h4>
          <p style={{ textAlign: "justify" }}>
            You are solely responsible for your interactions with other users of
            the Services. Drala is not responsible for and has no liability to
            you or to any third party in relation to your disputes with other
            users.
          </p>
          <br />
          <h4>Feedback and Complaints</h4>
          <p style={{ textAlign: "justify" }}>
            We want to ensure you have a positive user experience. If you would
            like to make a complaint or give us feedback, please contact us at
            our Drala.io website.
          </p>

          <br />
          <h4>U.S.A. Law Applies</h4>
          <p style={{ textAlign: "justify" }}>
            These Terms are governed by and are to be construed in accordance
            with, United States of America law. You irrevocably submit to the
            non-exclusive jurisdiction of the courts of the United States. This
            clause operates subject to the next clause (Arbitration).
          </p>

          <br />
          <h4>Arbitration</h4>
          <p style={{ textAlign: "justify" }}>
            You unconditionally and irrevocably agree that any dispute or
            disagreement arising out of or in connection with these Terms and/or
            the provision of the Services will be settled via arbitration by a
            sole arbitrator. The place of arbitration will be Greene County,
            Missouri and the law applicable to the arbitral proceedings and the
            matters in dispute will be United States of America law.
          </p>

          <br />
          <h4>Terms Survive Use</h4>
          <p style={{ textAlign: "justify" }}>
            These Terms will continue to apply to you after you stop using the
            Services, to the extent we reasonably think necessary or desirable.
          </p>

          <br />
          <h4>No Agency</h4>
          <p style={{ textAlign: "justify" }}>
            We are not your agent, associate, or representative for any purpose.
          </p>

          <br />
          <h4>Electronic Communications and Notices</h4>
          <p style={{ textAlign: "justify" }}>
            You consent to receive electronically all communications,
            agreements, documents, and disclosures (together, ‘Communications’)
            that we may or must provide in connection with your account, your
            wallets, or the Services. We will provide all Communications to you
            at the primary email address that you provide to our Drala.io
            website, or directly via any in-app communication tool. If we send
            an email to you at the primary email address you provide or using an
            in-app communication tool, you acknowledge that this constitutes
            ‘written notice’ from us to you. If you email us via our contact
            page at Drala.io or message us using the in-app communication tool,
            this constitutes ‘written notice’ from you to us. For all notices
            made by email, the date of receipt is considered to be the date of
            transmission. You consent to these electronic communications for all
            purposes. You must notify us immediately of any change to your email
            address if you supply us with one.
          </p>

          <br />
          <h4>Electronic Communications and Notices</h4>
          <p style={{ textAlign: "justify" }}>
            You consent to receive electronically all communications,
            agreements, documents, and disclosures (together, ‘Communications’)
            that we may or must provide in connection with your account, your
            wallets, or the Services. We will provide all Communications to you
            at the primary email address that you provide to our Drala.io
            website, or directly via any in-app communication tool. If we send
            an email to you at the primary email address you provide or using an
            in-app communication tool, you acknowledge that this constitutes
            ‘written notice’ from us to you. If you email us via our contact
            page at Drala.io or message us using the in-app communication tool,
            this constitutes ‘written notice’ from you to us. For all notices
            made by email, the date of receipt is considered to be the date of
            transmission. You consent to these electronic communications for all
            purposes. You must notify us immediately of any change to your email
            address if you supply us with one.
          </p>

          <br />
          <h4>Our Contact Details</h4>
          <p style={{ textAlign: "justify" }}>
            If you would like to contact us for any reason, please do so by
            email from our contact page at Drala.io.
          </p>
          <br />
          <h4>Intellectual Property</h4>
          <p style={{ textAlign: "justify" }}>
            All logos, content, materials, software, copyright, or creative
            derivative works on the Drala.io website, applications, and so forth
            are owned by private parties and provided to us for use. You are not
            authorized to copy, license, sell, distribute, reproduce or create
            derivative works from the Drala.io website, applications, and so
            forth without our prior written permission. Subject to your ongoing
            compliance with these Terms, we grant you a limited non-exclusive,
            non-transferable, revocable license to use the Drala software solely
            to access the Services. Please note we do not hereby assert any
            ownership of your personal data or any personal content that you
            share on any Drala application.
          </p>
          <br />
          <h4>No Waiver</h4>
          <p style={{ textAlign: "justify" }}>
            If we do not enforce, or delay in enforcing, any compliance by you
            or another person with these Terms, that does not mean that we have
            waived our rights and will not excuse any additional non-compliance
            by you with these Terms.
          </p>
          <br />
          <h4>Valid Clauses Saved</h4>
          <p style={{ textAlign: "justify" }}>
            If any provision of these Terms is invalid or unenforceable under
            applicable law, that provision will be treated as removed from these
            Terms and the remaining provisions will continue in full force and
            effect.
          </p>
          <h4>No Scraping</h4>
          <br />
          <p style={{ textAlign: "justify" }}>
            You may not use a robot, spider, scraper, or other unauthorized
            automated means to access the website or information featured on it
            for any purpose. You also may not manually scrape, harvest, or
            otherwise extract data from the Drala.io website without our express
            written permission.
          </p>

          <br />
          <h4>Links</h4>
          <p style={{ textAlign: "justify" }}>
            Please contact us for prior written permission if you wish to create
            a hypertext link to any page on or Drala.io website. If you do gain
            permission to create a link to the website, you remain fully
            responsible for any consequences of that link, whether direct or
            indirect, and you will protect Drala against all loss, damage,
            liability, costs, or expense arising from or in connection with the
            link.
          </p>

          <br />
          <h4>Drala is not responsible for other websites.</h4>
          <p style={{ textAlign: "justify" }}>
            Drala or the Drala Community is not responsible for the information
            on any other site accessed via the Drala.io website; that
            information is the responsibility of the owner of that site. Drala
            has no control over it. Links to other sites are provided for
            convenience only and do not represent any endorsement by Drala or
            the Drala Community of the services offered by the site owner.
          </p>

          <br />
          <h4>No Offer or Promise</h4>
          <p style={{ textAlign: "justify" }}>
            Drala is not, in the Drala.io website or applications, making any
            offer or promise to enter into any transaction with you nor to
            provide any services to you.
          </p>

          <br />
          <h4>Privacy</h4>
          <p style={{ textAlign: "justify" }}>
            These Terms also incorporate our Privacy Policy and you agree to
            abide by them as well.
          </p>

          <br />
          <h4>Cookies and Tracking Technologies</h4>
          <p style={{ textAlign: "justify" }}>
            To improve our website and services, we may allow third parties to
            use tracking technologies like “cookies” to collect statistical
            information about our users. The information collected is anonymous.
            However, you have the ability to opt out by disabling cookies in
            your browser or mobile settings. Drala is not responsible for any
            third-party’s cookies, web beacons, or other tracking technologies
            or their use of information collected.
          </p>

          <br />
          <h4>Term Changes</h4>
          <p style={{ textAlign: "justify" }}>
            We may change these Terms in the following circumstances:
            <ul>

              <li>
                To ensure that the Terms comply with all applicable laws and
                regulations, regulator guidance, or otherwise to ensure we meet
                our legal obligations and good market practice.
              </li>
              <li>
                To accommodate the requirements of our service providers or
                business partners.
              </li>
              <li>
                To reflect changes to our Services, the digital assets supported
                by the Services, the networks underlying any such digital assets,
                or the risks presented by them.
              </li>
              <li>
                In relation to fees for premium Services (if any), in response
                to changes to our costs of business or in reflection of demand for
                our Services.
              </li>
              <li>
                Otherwise where we consider it to be necessary or desirable to
                do so in order to protect our legitimate business interests.
              </li>
            </ul>
            If we amend these Terms, we will publish the updated Terms on the
            Drala.io website. If you have chosen to supply us with an up-to-date
            email address, we will also notify you via email. If you continue to
            access the Services then you will be bound by those amended or
            updated Terms.
          </p>
          <br />
          <h4>Entire Agreement</h4>
          <p style={{ textAlign: "justify" }}>
            These Terms as updated are the entire agreement between you and us
            in relation to the provision of the Services.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfServices;
