import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PublicRoutes = ({ component: Component, ...rest }) => {
  const { userRoot } = useSelector((store) => store);

  return (
    <>
      <Route
        {...rest}
        component={(props) =>
          userRoot.isAuthenticated ? (
            <Redirect to="/my/wallet" />
          ) : (
            <Component {...props} />
          )
        }
      />
    </>
  );
};

export default PublicRoutes;
