import React from "react";
import ReactPlayer from "react-player";
import styles from "./index.module.css";
// import YouTube from 'react-youtube';
function Section() {
  return (
    <>
      <div id={styles.sectionContainer}>
        <div id={styles.mainLogo}>
          <img src="./image/drala_homepage_logo.svg" alt="" />
        </div>

        <p className={styles.textContainer}>
          Drala is a new kind of money that uses peer-to-peer technology to
          operate with no government, central authority, or bank managing
          transactions. Everyone can take part since it is open-sourced and
          nobody owns or controls the Drala. All Drala transactions are free and
          carried out by the secure blockchain network.
        </p>
        <div id={styles.videosContainer}>
          <div className={styles.videoCards}>
            <div className={styles.name}>About Crypto</div>
            <div className={styles.videoBox}>
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                className={styles.reactPlayer}
                url="https://www.youtube.com/watch?v=jqQuNQXyaVk"
              />

              {/* <div className="whiteBox"> */}
              {/* <img src="./images/Polygon1.png" alt="" /> */}
              {/* </div> */}
            </div>
          </div>
          <div className={styles.videoCards}>
            <div className={styles.name}>The Drala Difference</div>
            <div className={styles.videoBox}>
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                className={styles.reactPlayer}
                url="https://youtu.be/Rhp5K5ILV0o"
              />

              {/* <div className="whiteBox"> */}
              {/* <img src="./images/Polygon1.png" alt="" /> */}
              {/* </div>
              https://youtu.be/ttv3dc1Ge1k
              */}
            </div>
          </div>
          <div className={styles.videoCards}>
            <div className={styles.name}>The Monetary Cancer</div>
            <div className={styles.videoBox}>
              <ReactPlayer
                width="100%"
                height="100%"
                className={styles.reactPlayer}
                controls
                url="https://youtu.be/ttv3dc1Ge1k"
              />
            </div>
          </div>
        </div>
      </div>
      <p className="description__text">
        Drala is a term that originates from Tibetan Buddhists and refers to a
        concept that combines the natural world with spirituality. Drala is
        believed to be the fundamental energy, or “God Force”, that permeates
        all aspects of Creation not only the physical environment but also the
        human mind.
        <br />
        <br />
        In Tibet, the word "drala" is a combination of two terms: "dra," which
        means "enemy" or "opponent," and "la," which means "above" or "beyond."
        Together, the term drala is understood as "beyond the enemy" or "beyond
        aggression."
        <br />
        <br />
        We chose Drala to be the name for our cryptocurrency because the
        practice of connecting with the God Force involves cultivating a deep
        appreciation and reverence for the natural world, recognizing the
        interconnectedness of all beings, and developing a sense of harmony with
        the environment. The Drala Cryptocurrency will stand above the corrupt
        monetary system to assist mankind in the next stage of evolution. --
        “Humanity only succeeds when everyone wins.”
      </p>
    </>
  );
}

export default Section;
