import React, { useEffect } from "react";
import styles from "./index.module.css";
import Footer from "../../footer";
import Navbar from "../../navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getClientContent } from "../../../Api/content.api";
function Propositions() {
  const { data, isLoading } = useQuery(
    ["propositionAdminContent"],
    () => axios.get("https://drala.io/api/vote/getVote"),
    {
      onSuccess: () => {},
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const content = useQuery(
    ["propositionContent"],
    () => getClientContent("8"),
    {
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {}, []);

  return (
    <>
      <Navbar />

      <div
        style={{
          marginTop: "0px",
          marginBottom: "100px",
        }}
        className={styles.propositionContainer}
      >
        <div className={styles.document}>
          <h1 style={{ textAlign: "center" }}>Propose a Proposition</h1>
          {!content.isLoading && (
            <div
              className={styles.document}
              dangerouslySetInnerHTML={{
                __html: content.data.data.content,
              }}
            />
          )}
        </div>

        {/* propositions */}
        <div className={styles.document}>
          {/* <h1 id="proposition">Propositions</h1> */}
          {!isLoading && data !== undefined && data.data !== undefined
            ? data.data.allVote.map((item, index) => (
                <div className={styles.content}>
                  <h3 className={styles.title}>{item.Title.English}</h3>
                  <p
                    className={styles.description}
                    style={{
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {item.Description.English}
                  </p>
                </div>
              ))
            : null}
        </div>
        <p>
          <strong>
            More propositions are found in the Voting section of your wallet.
          </strong>
        </p>
      </div>

      <Footer />
    </>
  );
}

export default Propositions;
