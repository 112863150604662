import React from 'react'
// import { useEffect, useState } from 'react'
import Footer from '../footer'
import Navbar from '../navbar'
import styles from './index.module.css'
import Posts from './posts/posts'
// import Sidebar from './sidebar/sidebar'
// import axios from "axios"

function News() {
  return (
    <>
      <Navbar newsColor="#FED900" />
      <div id={styles.newzContainer}>
        <Posts />
        {/* <Sidebar /> */}
      </div>
      <Footer />
    </>
  );
}

export default News
