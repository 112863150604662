import React from "react";
import { Link } from "react-router-dom";
import styles from "../index.module.css";
import src from "../../../images/download.png";

const Sidebar = () => {
  return (
    <>
      <div className={styles.mainn}>
        <ul className={styles.sidemenu}>
          <li>
            <Link to="/wallet">Account</Link>
          </li>
          <li>
            <Link to="/send">send drala</Link>
          </li>
          <li>
            <Link to="/receive">recieve drala</Link>
          </li>
          <li>
            <Link to="/transaction">transaction history</Link>
          </li>
          <li>
            <Link to="/Settings">settings</Link>
          </li>
          <li>
            <Link to="/contac">adress book</Link>
          </li>
        </ul>
        <div className={styles.app_down}>
          <h3>download drala wallet for android and ios</h3>
          <img src={src} alt="" />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
