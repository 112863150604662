import React, { Component, useRef } from "react";
import styles from "./index.module.css";
import Footer from "..";
import Navbar from "../../navbar";
import ClientCaptcha from "react-client-captcha";

import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

class ContactUs extends Component {
  refreshPage = () => {
    window.location.reload();
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      captch: "",
      capthaColde: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.form = React.createRef();
  }

  firsthandler = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  emailhandler = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  messagehandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  captchahandler = (event) => {
    this.setState({
      captch: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { capthaColde, captch } = this.state;
    console.log(capthaColde, captch);
    if (captch !== capthaColde) {
      this.setState({
        captch: "",
      });
      alert(`Please enter the correct Captcha Code !!!!`);
    } else {
      emailjs
        .sendForm(
          "service_t5tcuw1",
          "template_8an4ty9",
          this.form.current,
          "4AfrSYaa-61SEknis"
        )
        .then(
          (result) => {
            this.setState({
              name: "",
              email: "",
              message: "",
              captch: "",
              number: "",
            });
            toast.success("Message sent successfully!");
          },
          (error) => {
            console.log(error.text);
          }
        );
      event.preventDefault();
    }
  };

  render() {
    return (
      <>
        <Navbar />
        <div className={styles.grand_parent}>
          <section className={styles.touch}>
            <form ref={this.form} onSubmit={this.handleSubmit}>
              <h2>GET IN TOUCH</h2>
              <p>Feel free to drop us a line below!</p>
              <div className={styles.for}>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.firsthandler}
                  placeholder="Name"
                  required="required"
                  name="from_name"
                />
              </div>
              <div className={styles.for}>
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.emailhandler}
                  placeholder="Email"
                  required="required"
                  name="from_email"
                />
              </div>
              <div className={styles.for}>
                <input
                  type="text"
                  name="phone"
                  onwheel="this.blur()"
                  value={this.state.number}
                  // onChange={this.emailhandler}
                  placeholder="Phone Number (optional)"
                />
              </div>

              <div className={styles.for}>
                <textarea
                  value={this.state.message}
                  onChange={this.messagehandler}
                  name="message"
                  id="message"
                  cols="5"
                  rows="9"
                  placeholder="Type your message here."
                  required="required"
                ></textarea>{" "}
                <br />
              </div>
              <div className={styles.switchh}>
                <div className={styles.for}>
                  <input
                    type="text"
                    value={this.state.captch}
                    onChange={this.captchahandler}
                    placeholder="Enter Captcha"
                    required="required"
                  />
                </div>
                <div className={styles.captchaStyle}>
                  <ClientCaptcha
                    captchaCode={(code) =>
                      this.setState({
                        capthaColde: code,
                      })
                    }
                  />
                </div>
                <div
                  class="g-recaptcha"
                  data-sitekey="6Lf8qwkeAAAAAFtj3HXPkuGksihdE_gBcPWAsxWF"
                ></div>
                <div style={{ width: "100%", height: 40 }}></div>
                <input
                  className={styles.control}
                  type="submit"
                  value="Submit"
                />
              </div>
            </form>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
