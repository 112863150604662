import React from "react";
import styles from "./index.module.css";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import ReactPlayer from "react-player";

function Context() {
  return (
    <>
      <div id={styles.context}>
        {/* textContainer */}
        <div className={styles.textContainer}>
          {/* FAQ */}
          <div className={styles.text1Container}>
            <div className={styles.textHeading}>
              <Link to="/FAQ">Frequently Asked Questions</Link>
              {/* <a href="">Frequently Asked Questions</a> */}
            </div>
            <div className={styles.text}>
              <h4 className={styles.size}>1. What is cryptocurrency? </h4>
              <p>
                All over the world, different countries use their own money.
                This allows countries to have better control over their
                economies and people. Now cryptocurrency, or crypto for short,
                is not like paper money where you can hold it in your hand. It
                is just digital money that is not controlled by a government or
                banking system. Cryptocurrency can be run by individuals,
                companies, or{" "}
                <span className={styles.readmore}>
                  <Link to="/FAQ">...Read More</Link>
                </span>
              </p>
            </div>
          </div>

          {/* EDUCATIONAL PAPER */}
          <div className={styles.text2Container}>
            <div className={styles.textHeading}>
              <Link to="/educationalPaper">Educational Paper</Link>
            </div>
            <div className="texts">
              <p>
                {" "}
                The first step to understanding the Drala Cryptocurrency is to
                forget everything you know or want to know about cryptocurrency.
                Let's start fresh . . .{" "}
              </p>
              <p>
                Currently, numerous cryptocurrencies have and are being created
                that will make millions/billions of dollars for their
                developers, whether it be for some useful application, network
                shopping rewards program{" "}
                <span className={styles.readmore}>
                  {" "}
                  <Link to="/educationalPaper">...Read More</Link>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.textContainer2}>
          {/* PROPOSITIONS */}
          <div className={styles.text1Container}>
            <div className={styles.textHeading}>
              <Link to="/propositions#proposition">Propositions</Link>
            </div>
            <div className={styles.text}>
              <p>
                <strong>Proposition 1:</strong> The originally affiliated
                non-profits have had the Drala dispersed into their twelve
                different wallets. This first proposition is for the non-profits
                to confirm their integrity agreement to the following
                conditions:
              </p>

              <p>
                1. To keep their wallets and private keys safe and secure at all
                times.
              </p>
              <p>
                2. To only disperse the Drala according to the schedule found in
                the Educational Paper.
              </p>
              <p>
                3. To use 100% of any funds{" "}
                <span className={styles.readmore}>
                  {" "}
                  <Link to="/propositions">...Read More</Link>
                </span>
              </p>
            </div>
          </div>

          {/* PROPOSE A PROPOSITION */}
          <div className={styles.text2Container}>
            <div className={styles.textHeading}>
              <Link to="/propositions">Propose a Proposition</Link>
            </div>
            <div className={styles.text}>
              <p>
                Any Drala holder can propose a proposition to be voted on by the
                community. Since there can be significant time involved in
                organizing the proposition for a vote by the community, we
                request that a donation be sent to the non-profit’s wallet below
                according to the suggested donation schedule. Any proposition
                received without “proof of donation”, will be reviewed and
                scheduled as the volunteer’s time permits.
              </p>
              <p>
                To send a proposition, please{" "}
                <span className={styles.readmore}>
                  {" "}
                  <Link to="/propositions">...Read More</Link>
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* videoContainer */}

        {/* <div className={styles.videoContainer}>
          <div className={styles.videoText}>
            How to use your mobile Drala Wallet.
          </div>
          <div className={styles.videoBox}>
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              className={styles.reactPlayer}
              url="https://www.youtube.com/watch?v=VWNTRZPG_QY"
            />

          </div>
        </div> */}
      </div>
    </>
  );
}

export default Context;
