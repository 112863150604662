import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../footer";
import Navbar from "../../navbar";
import "./style.css";

function ACLists() {
  const [businessData, setBusinessData] = useState([]);
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const { data } = await axios.get("https://drala.io/api/admin/business");
        console.log(data.business);
        setBusinessData(data.business.reverse());
      } catch (error) {
        console.log(error);
      }
    };
    fetchBusinessData();
  }, []);

  return (
    <>
      <Navbar />
      <main className={"business_list__container"}>
        <h2>Businesses that accept the Drala for payment.</h2>

        <ul className={"business_list__wrapper"}>
          {businessData.length > 0 &&
            businessData.map((item) =>
              item.business.map((data) => (
                <li className={"business_list__item"}>
                  <div className="business_list__item__image__wrapper">
                    <img src={data.logo} alt={data.name} width={80} />
                    <div className={"business_list__item__content"}>
                      <p className={"business_list__item__content__name"}>
                        {data.name}
                      </p>

                      {data.website_link !== "" && (
                        <a
                          className="business_list__item__content__link"
                          href={`http://${data.website_link}`}
                        >
                          Visit Website Page
                        </a>
                      )}
                      <p className="business_list__item__content__telephone">
                        {" "}
                        Telephone: {data.telephone}
                      </p>

                      {data.facebook_page !== "" && (
                        <a
                          href={data.facebook_page}
                          className="business_list__item__content__facebook"
                        >
                          Visit Facebook Page
                        </a>
                      )}
                    </div>
                  </div>
                  <p>
                    <strong>Description</strong>
                  </p>
                  <div
                    className="business_list__item__content__description"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </li>
              ))
            )}
        </ul>
      </main>
      <Footer />
    </>
  );
}

export default ACLists;
