import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Footer from "../footer";
import Navbar from "../navbar";
import src1 from "../../images/image 316.png";

import src2 from "../../images/image 1.png";
import src3 from "../../images/comingsoon.png";
import axios from "axios";

function Exchange() {
  const [data, setData] = useState(false);

  useEffect(() => {
    axios
      .get("https://drala.io/api/converter/fetchCurrencyRates")
      .then((res) => {
        // res.data.data[0].CurrencyRates.map((item) => {
        //   console.log(item);
        // });
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar exchangeColor="#FED900" />
      <div id={styles.exchangeContainer}>
        <div className={styles.image1}>
          <img src={src1} alt="no img" />
        </div>

        {/* 
        
        EUR:0.96
        GBP:0.83
        INR:81.72
        JPY: 138.15
        KRW:1328.35
        RUB:61.06
        USD:1
         */}

        <div className={styles.image2}>
          {/* <img src={src2} alt="no img" /> */}
          <div className={styles.comparison_container}>
            <div className={styles.comparison_card}>
              <p>
                1 <span>Drala</span>
              </p>
            </div>
            <p>=</p>
            {data !== false && (
              <ul className={styles.comparison_list}>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.USD} <span>USD</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.EUR} <span>EUR</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.GBP} <span>GBP</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.INR} <span>INR</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.JPY} <span>JPY</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.KRW} <span>KRW</span>
                  </p>
                </li>
                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.RUB} <span>RUB</span>
                  </p>
                </li>

                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.AED} <span>AED</span>
                  </p>
                </li>

                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.CNY} <span>CNY</span>
                  </p>
                </li>

                <li className={styles.comparison_card}>
                  <p>
                    {data.data[0].CurrencyRates.MXN} <span>MXN</span>
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
        <h2>Trusted exchanges to be linked soon</h2>
        <div className={styles.textCont}>
          <p>
            {/* Since the exchanges are independent companies that charge fees for
            their services, we desire people to legally purchase their Drala
            directly from Community Members or Partnered Businesses they trust.
            (In the USA the amount to legally purchase is $1,000.00 worth of
            Drala per day from each person or business. So, if you wanted to
            purchase $2,000.00 worth of Drala in a day you would have to do that
            in a minimum of two transactions from two or more different people
            or businesses.)*/}
            Since the exchanges are independent companies that charge fees for their services, we desire people to legally purchase their Drala directly from Community Members or Partnered Businesses they trust. Please follow the laws of your country. In the USA the amount to legally purchase is $1,000.00 worth of Drala per day from each person or business. So, if you want to purchase $2,000.00 worth of Drala in a single day, you will have to do that in two or more transactions from two or more different people or businesses. (If you need assistance purchasing Drala, please contact us and we will connect you to a trusted individual.) 
            <br />
            <br />
            Sadly, most exchanges are into taking advantage of the lack of legal
            rules by manipulating prices to make more profits. A few years ago,
            one of the Presidents of a partnered non-profit caught a major
            exchange selling a coin that they did not own. (His group had
            accumulated over 65% of the coins and had them off the exchange.) He
            showed the exchange the proof of their price manipulation and
            selling of coins that they did not own. The exchange stopped
            answering his emails. In less than a month they had completely
            changed how their orders were being displayed so their manipulation
            could no longer be proven.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Exchange;
