import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";
import { Link } from "react-router-dom";
import LogCSS from "../Login/log.module.css";
import src from "../../images/logo1.svg";
const Log = () => {
  return (
    <>
      <Navbar />
      <div className={LogCSS.body}>
        <div className={LogCSS.trans}>
          <div className={LogCSS.st}>
            <div className={LogCSS.st_image}>
              <img src={src} alt="" />
            </div>
            <p>Better Money for a Better Planet</p>
            <p className="warning-msg">*Warning! Only use this online service from your private personal computer that is secure from viruses. If someone has access to your account or steals your Secret Phase, they can steal your Drala!</p>
          </div>

          <div className={LogCSS.btnlog}>
            <Link to="/create">Create Online account</Link>
            {/* <p>or</p>
            <Link to="/signin">Log in with Existing account</Link>
             */}
            <p>or</p>
            <Link to="/importWallet">import wallet</Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Log;
