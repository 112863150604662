import React from 'react'
import styles from './index.module.css'
import Footer from '../footer'
import Navbar from '../navbar'
import src from "../../images/comingsoon.png";

function Xplorer() {
  return (
    <>
      <Navbar explorerColor="#FED900" />
      <div id={styles.explorerContainer}>
        <img src={src} alt="" />
      </div>
      <Footer />
    </>
  );
}

export default Xplorer
