import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./index.module.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import ReactPlayer from "react-player";

function SinglePost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const getPost = async () => {
      const { data } = await axios.get(`https://drala.io/api/news/get/${id}`);
      setPost(data.data);
      setDataLoaded(true);
    };
    getPost();
  }, [id]);

  return (
    <>
      {dataLoaded ? (
        <div id={styles.singlePost}>
          <h3>News Drala Release</h3>
          <div className={styles.singlePostWrapper}>
            <h2>{post.title}</h2>
            <div className={styles.date}>
              {new Date(post.createdAt).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div className={styles.b1}>
              {post.image != "" &&
              post.image != "false" &&
              post.image !== "noimage" ? (
                <img className={styles.singlePostImg} src={post.image} alt="" />
              ) : (
                <img
                  className={styles.singlePostImg}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
                  }
                  alt=""
                />
              )}
            </div>
            <Box sx={{ flexGrow: 1 }} style={{ marginTop: 30 }}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <div
                    className={styles.desc}
                    dangerouslySetInnerHTML={{ __html: post.description }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <ReactPlayer url={post.videoLink} width="100%" />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default SinglePost;
