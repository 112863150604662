import axios from "axios";
import React, { useEffect, useState } from "react";
import AnimatedNumber from "react-animated-number";
import assetsIcon from "../../images/assetsIcon.png";
import src1 from "../../images/image 316.png";
import src3 from "../../images/image 317.png";
import Footer from "../footer";
import Navbar from "../navbar";
import styles from "./assets.module.css";

function Assets() {
  const [counter, setCounter] = useState(99.0);
  const [AssetsData, setAssetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://drala.io/api/admin/primaryAsset"
        );
        setAssetsData(data.asset);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchAssets();
  }, [counter]);

  return (
    <>
      <Navbar assetsColor="#FED900" />
      <div className={styles.assetContainer}>
        <div className="image">
          <img src={src1} width={200} alt="" />
        </div>

        {/* This is an AUTOMATIC COUNTER */}

        {/* <div className={styles.counter}>
          $
          <AnimatedNumber
            onLoad={() => setCounter(counter + 11.0)}
            value={10000000000000}
            formatValue={(value) => parseInt(value).toLocaleString("en")}
            duration={10000}
          />
        </div> */}
        {loading && <div className={styles.loadingText}>Loading ....</div>}
        <div className={styles.texContainer} style={{ lineHeight: 2 }}>
          {AssetsData.length > 0 && (
            <div
              dangerouslySetInnerHTML={{ __html: AssetsData[0].description }}
            />
          )}
        </div>
        {/* <div className={styles.head1}>
       
          <h2>
            Non-Profit Declarations of Assets to Back the Drala First Quarter
            2023.
          </h2>
</div>*/}
        {/* <div className={styles.texContainer}>
          <p>
            The following tangible assets, in US Dollars, have been pledged to
            fully back the Drala. Drala holders have the right to propose a vote
            to liquidate some or all these funds to be used to purchase the
            Drala from the open market. All asset holders have agreed to abide
            by the 60% majority vote to liquidate any asset or use any asset for
            any suggested proposal. All assets have been reported to and
            verified by the administrator of this website. To protect the
            backers and the assets themselves, the administrator does not
            disclose where the assets are from. Not all assets have been proven
            below, but if you need more proof than what is provided in the files
            below, please contact the administrator and make your request.
          </p>
          <ol>
            <li>A total of $246,557.30 cash in the bank.</li>
            <li>Approximated $25,000+ in World currency besides USD.</li>
            <li>
              A total of 1,520,840.5 USDT and $57,407.83 in other cryptocurrency
              in an Exodus Wallet.
            </li>
            <li>A total of $382,527.43 in Paxos cryptocurrency exchange.</li>
            <li>
              Silver and other nominal amounts of other precious metals
              estimated value $45,000+.
            </li>
            <li>
              Other miscellaneous assists that are easily liquidated are worth
              $30,000+.
            </li>
            <li>
              There are currently 999,989,991 Drala in the Non-Profit
              organizations’ possession.
            </li>
            
          </ol>
        </div>*/}

        {/*  <div className={styles.head2}>
          <h2>Current Tangible Asset Backing $2,307,333.06.</h2>
      </div>*/}
        <div className={styles.files}>
          <h3 style={{ textAlign: "center" }}>
            Downloads for Asset Authentication:
          </h3>
          <div className={styles.downloadFiles}>
            {AssetsData.length > 0 &&
              AssetsData.map((item) =>
                item.attachment.map((data) => (
                  <div className="imgboxAssets">
                    <a href={data.attachment} download={data.name}>
                      <img src={assetsIcon} alt={data.name} height="48" />
                    </a>
                    <p>{data.name}</p>
                  </div>
                ))
              )}
          </div>
        </div>
        <div className={styles.dralaUnited}>
          <img src={src3} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Assets;
