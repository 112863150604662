import React from "react";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import src from "../../images/logo1.svg";

const axios = require("axios").default;
// import 'bootstrap/dist/css/bootstrap.min.css'

function Navbar(props) {
  const [options, setOptions] = useState([]);
  const [to, setTo] = useState("en");
  const [output, setOutput] = useState("");
  const [sidebar, setSidebar] = useState(false); //toggle hamburger menu
  const [ham, setHam] = useState(true);

  const [isSmall, setIsSmall] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const hambur = () => setHam(!ham);

  const translate = () => {
    // curl -X POST "https://libretranslate.com/translate" -H  "accept: application/json" -H  "Content-Type: application/x-www-form-urlencoded" -d "q=hello&source=en&target=es&format=text&api_key=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"

    axios.post(
      "https://libretranslate.com/translate",
      {
        q: output,
        target: to,
        api_key: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  useEffect(() => {
    if (window.innerWidth < 950) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }

    axios
      .get("https://libretranslate.com/languages", {
        headers: { accept: "application/json" },
      })
      .then((res) => {
        console.log(res.data);
        setOptions(res.data);
      });
  }, []);

  // {
  //    curl -X GET "https://libretranslate.com/languages" -H  "accept: application/json"
  // }

  return (
    <>
      <div id={styles.navbar} onOutput={(e) => setOutput(e.target.value)}>
        <div className={styles.margin_top}>
          <div className={styles.logo1}>
            <Link to="/">
              <img src={src} alt="" />
            </Link>

            <button
              className={styles.side_bar}
              onClick={() => {
                showSidebar();
                hambur();
              }}
            >
              <i
                className={ham ? "fa fa-bars" : "fa fa-times"}
                aria-hidden="true"
              ></i>
            </button>
          </div>

          <div className={sidebar ? "bar active" : "bar"} id="bar">
            <ul
              style={{
                left: sidebar ? "0" : "-100%",
              }}
              className={styles.right}
              onClick={() => {
                showSidebar();
                hambur();
              }}
            >
              <li>
                <Link
                  to="/"
                  style={{ color: props.homeColor ? props.homeColor : "white" }}
                >
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  to="/wallet-landing"
                  style={{
                    color: props.walletColor ? props.walletColor : "white",
                  }}
                >
                  WALLET
                </Link>
              </li>
              <li>
                <Link
                  to="/news"
                  style={{ color: props.newsColor ? props.newsColor : "white" }}
                >
                  NEWS
                </Link>
              </li>
              <li>
                <Link
                  to="/assets"
                  style={{
                    color: props.assetsColor ? props.assetsColor : "white",
                  }}
                >
                  ASSETS
                </Link>
              </li>
              <li>
                <Link
                  to="/exchange"
                  style={{
                    color: props.exchangeColor ? props.exchangeColor : "white",
                  }}
                >
                  EXCHANGE
                </Link>
              </li>
              <li>
                <Link
                  to="/communities"
                  style={{
                    color: props.communitiesColor
                      ? props.communitiesColor
                      : "white",
                  }}
                >
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link
                  to="/usedrala"
                  style={{
                    color: props.useDralaColor ? props.useDralaColor : "white",
                  }}
                >
                  USE DRALA
                </Link>
              </li>
              {/* <li className={styles.languagebar}>

                <span>
                  <select
                    onChange={(e) => setTo(e.target.value)}
                    onClick={(e) => translate()}
                  >
                    {options.map((opt) => (
                      <option key={opt.code} value={opt.code}>
                        {opt.name}
                      </option>
                    ))}

                  </select>
                </span>
              </li> */}
              {/* <li className="languagebar">
                <a>
                  <button className="btn btn-success">
                    <span className="text"> English (UK)</span>
                    <span className="uk">
                      <img src="./images/uk.png" alt="no image" />
                    </span>
                    <span className="polygon">
                      <img src="./images/Polygon.png" alt="no image" />
                    </span>
                  </button>
                </a>
              </li> */}
              {/* <li className="logo2">
                              <img src="./images/image315.png" alt="no image" />
                          </li>
                          <li className="logotext">SAM</li>
                          <li className="polygon2">
                              <img src="./images/polygon2.png" alt="no image" />
                          </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
